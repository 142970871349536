import React from "react";
import { useEffect } from "react";
import "./App.css";
import Navigation from "./components/NavBar";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Insurance from "./pages/Insurance";
import Preparation from "./pages/Preparation";
import Faq from "./pages/Faq";



const App = () => {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change
  
  
  

  return (
    <>
      <Navigation   />
      
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/insurance" element={<Insurance />} />
        <Route path="/preparation" element={<Preparation />} />
        <Route path="/faq" element={<Faq />} />
      </Routes>
    </>
  );
};

export default App;
