import { Container, Row } from "react-bootstrap";
import {
  faDroplet,
  faFireFlameCurved,
  faHeadSideCough,
  faFaceFrownOpen,
  faToilet,
  faCapsules,
} from "@fortawesome/free-solid-svg-icons";
import ConditionItem from "./ConditionItem";

const conditions_info = [
  {
    icon: faFaceFrownOpen,
    title: "Abdominal pain",
    description:
      "Abdominal pain has many causes, and can occur in organs of the digestive tract. If you are experiencing chronic, repetitive, or severe abdominal pain, it is important to get a diagnosis as soon as possible.",
    id: 1,
  },
  {
    icon: faFireFlameCurved,
    title: "Acid reflux and heartburn",
    description:
      "Acid reflux, or heartburn, is the backward flow of stomach acid into the esophagus. If you have acid reflux on a regular basis, even a few times a month, you should be evaluated to make sure your esophagus is healthy. Acid reflux may require more effective treatment than over-the-counter medication.",
    id: 2,
  },
  {
    icon: faHeadSideCough,
    title: "Belching, bloating, and gas",
    description:
      "With an advanced network and a team approach, we provide an efficient diagnosis, customized treatment and preventive care",
    id: 3,
  },
  {
    icon: faToilet,
    title: "Constipation and diarrhea",
    description:
      "With an advanced network and a team approach, we provide an efficient diagnosis, customized treatment and preventive care",
    id: 4,
  },
  {
    icon: faDroplet,
    title: "Rectal bleeding",
    description:
      "With an advanced network and a team approach, we provide an efficient diagnosis, customized treatment and preventive care",
    id: 5,
  },
  {
    icon: faCapsules,
    title: "Difficulty swallowing",
    description:
      "With an advanced network and a team approach, we provide an efficient diagnosis, customized treatment and preventive care",
    id: 6,
  },
];

const Conditions = () => {
  return (
    <Container id="conditions" className="mt-2 py-4">
      <Row className="mt-5">
        <h1 className="section-header text-center fw-bold">CONDITIONS</h1>

        <p className="text-center lead">
          Below you can find some of the common conditions Dr. Gordin treats. If you are experiencing any of these be sure to make an appoinment today. 
        </p>
      </Row>
      <Row>
        {conditions_info.map((condition) => (
          <ConditionItem
            title={condition.title}
            icon={condition.icon}
            description={condition.description}
            key={condition.id}
          />
        ))}
      </Row>
    </Container>
  );
};

export default Conditions;
