import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faPhone,
  faEnvelope,
  faFax,
} from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col } from "react-bootstrap";
import classes from "./ContactUs.module.css";

const Contact = () => {
  return (
    <>
      <Container id="contact" className="mt-2 py-4">
        <Row className="mt-5">
          <h1 className="section-header text-center fw-bold">CONTACT US</h1>

          <p className="text-center lead">
            Please give us a call at (718) 382-0500 to make an appointment. Our
            office is usually open 9AM-5PM.
          </p>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col lg={4}>
            <div
              className={`${classes.info} d-flex flex-column justify-content-between`}
            >
              <div className={classes["info-row"]}>
                <FontAwesomeIcon
                  icon={faLocationDot}
                  className={classes["icon-info__address"]}
                />
                <div className={classes.details}>
                  <h4 className={classes["info-heading"]}>Address:</h4>
                  <p>
                    2647 Coney Island Ave
                    <br />
                    Brooklyn, NY 11223
                  </p>
                </div>
              </div>
              <div className={classes["info-row"]}>
                <FontAwesomeIcon
                  icon={faPhone}
                  className={classes["icon-info"]}
                />
                <div className={classes.details}>
                  <h4 className={classes["info-heading"]}>Call:</h4>
                  <p className="lead">(718) 382-0500</p>
                </div>
              </div>
              <div className={classes["info-row"]}>
                <FontAwesomeIcon
                  icon={faFax}
                  className={classes["icon-info"]}
                />
                <div className={classes.details}>
                  <h4 className={classes["info-heading"]}>Fax:</h4>
                  <p className="lead">(718) 382-7025 </p>
                </div>
              </div>
              <div className={classes["info-row"]}>
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className={classes["icon-info"]}
                />
                <div className={classes.details}>
                  <h4 className={classes["info-heading"]}>Email:</h4>
                  <p className="lead">info@gordinoffice.com</p>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={8}>
            <iframe
              width="100%"
              title="map"
              height="100%"
              style={{ border: 0 }}
              loading="lazy"
              allowFullScreen
              src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ_7nKrJREwokR1sXTAQlDSMQ&key=AIzaSyCu0kM7J3-6LmiCb7Ta_yeLXbb8tydSG2w"
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Contact;
